import React from 'react'
import { graphql } from 'gatsby'

import Layout, { PreFooter } from '../components/Layout'
import SEO from '../components/seo'

import Fade from 'react-reveal/Fade'

import PAGE_CONTENTS from '../../content/pages/terms.json'
import MarkdownStringRenderer from '../components/MarkdownStringRenderer'

class Terms extends React.Component {
  render() {
    const { data } = this.props
    const siteTitle = data.site.siteMetadata.title

    return (
      <Layout
        location={this.props.location}
        title={siteTitle}
        HeaderExtension={
          <div className="header_extension no_bg press aligncenter">
            <div className="bg">
              <div className="container">
                <Fade bottom duration={1000} delay={125} distance={'25%'}>
                  <h2>Terms of use</h2>
                </Fade>
              </div>
            </div>
          </div>
        }
      >
        <SEO
          title="Terms of use"
          description="Read our terms of use to understand what a user agrees to in order to use our website."
        />

        <div className="page-content pages-press">
          <div className="section-press">
            <div className="container">
              <div className="columns post-single ui-grid">
                <div className="column is-10 content">
                  <MarkdownStringRenderer
                    markdownString={PAGE_CONTENTS.content}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <PreFooter
          title="Ready to take off with Knocking?"
          linkText="Let’s talk"
          linkTo="/contact"
        />
      </Layout>
    )
  }
}

export default Terms

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
